<template>
  <section>
    <div v-if="!loading">
      <button
        v-on:click="loginSubmit"
        class="mt-2 btn btn-fill btn-block btn-primary"
      >
        {{ $t("LOGIN.SUBMIT") }}
      </button>
    </div>
    <div class="text-center" v-if="loading">
      <div v-if="!showOnThisDevice">
        <b-img :src="frejaQrCode" :key="auth_uuid"></b-img>
        <InformationMessage
          v-if="hintMessage != 'outstandingTransaction'"
          class="mt-2 mb-2"
          type="information"
        >
          {{ statusMessage }}
        </InformationMessage>
        <a variant="primary" :href="urlDevice" target="_blank">{{ $t("SAME_DEVICE") }}</a>
      </div>
      <div class="mt-2 mb-2" v-if="showOnThisDevice">

        <a class="btn btn-fill btn-primary text-white" target="_blank" :href="urlDevice">
          {{ $t("SAME_DEVICE") }}</a
        > <br />
          <br />
          <a href="#" @click.prevent="otherDevice">
            {{ $t("OTHER_DEVICE") }}</a>
      </div>

      <button @click.prevent="cancelSubmit" class="mt-2 btn btn-secondary">
        {{ $t("CANCEL") }}
      </button>
    </div>
  </section>
</template>
<script>
import mobileDetect from "mobile-device-detect";
export default {
  props: ["loginInfo"],
  components: {
    InformationMessage: () => import("@/components/Layout/InformationMessage"),
  },
  data() {
    return {
      device: mobileDetect,
      loading: false,
      frejaQrCode: "",
      errorMessage: "",
      hintMessage: "",
      authRef: "",
      auth_uuid: "",
      statusMessage: "",
      timer: "",
      showOnThisDevice: false
    };
  },
  methods: {
    otherDevice: function() {
      this.showOnThisDevice = false;
    },
    reset: function() {
      clearTimeout(this.timer);
      this.loading = false;
      this.authRef = "";
      this.showOnThisDevice = this.device.isMobile;
      this.$emit("loading", false);
    },
    cancelSubmit: function() {
      let self = this;
      this.$http
        .get(self.user.hostname + "/authenticate/cancel/" + this.auth_uuid)
        .then(function() {
          self.reset();
        })
        .catch(function() {
          self.reset();
        });
    },
    loginSubmit: function() {
      this.$emit("loading", true);
      this.loading = true;
      let self = this;
      this.$http
        .post(self.user.hostname + "/authenticate", {
          email: this.user.email,
          auth_method: this.loginInfo.auth_method,
          auth_type: this.loginInfo.auth_type,
          client: "addin",
        })
        .then(function(result) {
          let redirect = false;
          if(result.data.redirect != undefined)
          {
            if(result.data.redirect == 1)
            {
              redirect = true;
            }
          }
          if(redirect)
          {
            self.$emit('startAuthentication',result.data.request_url);
          }else{
            self.statusMessage = "";
            self.auth_uuid = result.data.auth_uuid;
            self.authRef = result.data.authRef;
            self.bindUserToTransaction();
          }
        })
        .catch(function(error) {
          self.reset();
          self.$store.dispatch('logger/add','authenticated error loginSubmit');
          if(error.toString() == "Error: fail_4_4") return;
          if(error.response != undefined)
          {
            self.$noty.error(self.$t(error.response.data));
          }
        });
    },
    bindUserToTransaction: function() {
      let qrcodedata = encodeURI(
        "frejaeid://bindUserToTransaction?transactionReference=" + this.authRef
      );
      this.frejaQrCode =
        "https://resources.prod.frejaeid.com/qrcode/generate?qrcodedata=" +
        qrcodedata;
      this.timer = setTimeout(this.checkStatus(), 1000);
    },
    checkStatus: function() {
      let self = this;
      if (this.authRef != "") {
        this.loading = true;
        this.$http
          .get(self.user.hostname + "/authenticate/status/" + this.auth_uuid)
          .then(function(result) {
            if (result.status == 200) {
              clearTimeout(self.timer);
              self.verifyAuthentication();
            }
            if (result.status == 202) {              
              if(result.data.hintCode != undefined)
              {
                if(result.data.hintCode == "EXPIRED"){        
                  self.reset();  
                }else{
                  self.hintMessage = result.data.hintCode;
                  self.statusMessage = self.$t("FREJA.PROCESS." + result.data.hintCode);
                  self.timer = setTimeout(self.checkStatus, 1000);
                }
              }else{
                if(result.data == "EXPIRED"){        
                  self.reset();  
                }else{
                  self.hintMessage = result.data;
                  self.statusMessage = self.$t("FREJA.PROCESS." + result.data);
                  self.timer = setTimeout(self.checkStatus, 1000);
                }
              }       
            }
          })
          .catch(function(error) {
            self.reset();
            if(error.toString() == "Error: fail_4_4") return;
            self.$noty.error(self.$t(error.response.data));
          });
      }
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(self.user.hostname + "/authenticate/result/" + this.auth_uuid)
        .then(function(result) {
          self.$emit("authenticated",result.data.token);
        })
        .catch(function(error) {
          self.reset();
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t(error.response.data));
        });
    },
  },
  computed: {
    urlDevice() {
      return encodeURI(
        "frejaeid://bindUserToTransaction?transactionReference=" + this.authRef
      );
    }
  },
  mounted: function() {
    this.loading = false;
    this.showOnThisDevice = this.device.isMobile;
  },
};
</script>
<style></style>
